import { RelatedContentGridHome } from '/features/pageOnly/article/RelatedContentGrid'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { PortableTextMainContent } from './PortableText'
import { routeMap } from '/routeMap'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/client-safe/sanity'

import styles from './TilesWithTextAndRelatedContent.css'

export function TilesWithTextAndRelatedContent({ tiles, blocks, label, availableTileStyleContexts }) {
  return (
    <div className={styles.component}>
      <div className={styles.content}>
        {Boolean(tiles?.length && tiles[1]) && <Tile {...tiles[1]} href={determineDocumentPathSync({ document: tiles[1], routeMap })} />}
        {blocks && <PortableTextMainContent layoutClassName={styles.textLayout} value={blocks} />}
        {label && <p className={styles.label}>{label}</p>}
      </div>
      {tiles && <RelatedContentGridHome availableStyleContexts={availableTileStyleContexts} {... { tiles }} />}
    </div>
  )
}
